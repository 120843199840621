import { endOfDay } from 'date-fns';
import pptxgen from 'pptxgenjs';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { getQueryParam } from '../../../hooks/useQueryParam';
import { apiService } from '../../../services/api-service';
import { type Organization } from '../../../types';
import { type AnalyticsFilter } from '../../Organization/Details';
import { addSlideEngagement } from './AnalyticsSlideEngagement';
import { addSlideLPUpdate } from './AnalyticsSlideLPUpdate';
import { addSlideNewGames } from './AnalyticsSlideNewGames';
import { pxToInch } from './AnalyticsSlideShared';

function parseDate(dateString: string | null) {
  if (!dateString) return null;
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(Date.UTC(year, month - 1, day));
}

export function AnalyticsDownloadSlidesBanner(props: {
  organization: Organization;
  filter: AnalyticsFilter;
}): JSX.Element {
  const { organization, filter } = props;

  const {
    call: handleDownload,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    const startAt = parseDate(getQueryParam('slides-start-at'));
    let endAt = parseDate(getQueryParam('slides-end-at'));
    if (endAt) {
      endAt = endOfDay(endAt);
    }

    const { data: summary } =
      await apiService.organization.getPresentationSummary(organization.id, {
        timeRange: filter.timeRange,
        startAt: startAt ? startAt.toISOString() : null,
        endAt: endAt ? endAt.toISOString() : null,
      });

    const pres = new pptxgen();

    pres.defineLayout({
      name: '1920_1080',
      width: pxToInch(1920),
      height: pxToInch(1080),
    });
    pres.layout = '1920_1080';

    addSlideLPUpdate({ pres, organization, summary });
    addSlideNewGames({ pres, organization, summary });
    await addSlideEngagement({ pres, organization, summary });

    await pres.writeFile({ fileName: 'Luna Park Update.pptx' });
  });

  return (
    <div className='w-full h-full flex justify-between items-center'>
      <div></div>
      <button
        type='button'
        className='btn-secondary w-80 h-10'
        onClick={handleDownload}
        disabled={isRunning}
      >
        {isRunning ? 'Downloading...' : 'Download PPT Slide Summary'}
      </button>
    </div>
  );
}
